$(document).ready(() => {
  initPriceSlider();
  initFilters();
  initTabs();
  initProductPhotos();
  initCardsSlider();
  initHeader();
  initModals();
  initSubscribeForm();
});

function initPriceSlider() {
  const slider = $('.price-slider__slider');
  const min = parseInt(slider.attr('data-min'));
  const max = parseInt(slider.attr('data-max'));
  const valuesLabels = [

  ];
  const minLabel = $('.price-slider__labels span:nth-child(1)');
  const maxLabel = $('.price-slider__labels span:nth-child(2)');

  if (slider.length) {
    noUiSlider.create(slider[0], {
      range: {
        'min': min,
        'max': max,
      },
      start: [min, max],
      step: 1,
      connect: true,
    });
  
    slider[0].noUiSlider.on('update', function (values, handle) {
      minLabel.html(parseInt(values[0]));
      maxLabel.html(parseInt(values[1]));
    });
  }

  
}

function initFilters() {
  $('.filters__toggle').click(function(e) {
    e.preventDefault();
    $(this).closest('.filters__group').toggleClass('is-active');
  });

  $('.catalog-open-filters').click(function(e) {
    e.preventDefault();
    $('.catalog__filter').addClass('is-active');
  });

  $('.catalog__filter-close').click(function(e) {
    e.preventDefault();
    $('.catalog__filter').removeClass('is-active');
  });
}

function initTabs() {
  $('.tabs__nav a').click(function(e) {
    e.preventDefault();

    console.log('click');

    $(this).addClass('is-active').siblings().removeClass('is-active');
    $($(this).attr('href')).addClass('is-active').siblings().removeClass('is-active');
  });

  $('.tabs__tab-toggle').click(function(e) {
    e.preventDefault();

    $(this).closest('.tabs__tab').toggleClass('is-open');
  });
}

function initProductPhotos() {
  const slider = new Swiper('.product-gallery', {
    slidesPerView: 1,
  });

  slider.on('slideChange', function(e) {
    $(`.product-gallery-nav__photo[data-index="${e.activeIndex}"]`).addClass('is-active').siblings().removeClass('is-active');
  });

  $('.product-gallery-nav__photo').click(function(e) {
    e.preventDefault();
    const index = parseInt($(this).attr('data-index'));
    slider.slideTo(index, 500, false );
  });
}

function initCardsSlider() {
  const sliders = $('.cards-slider');

  $.each(sliders, (k, v) => {
    new Swiper($(v).find('.cards-slider__main')[0], {
      slidesPerView: 2,
      spaceBetween: 20,
      navigation: {
        nextEl: $(v).find('.cards-slider__next')[0],
        prevEl: $(v).find('.cards-slider__prev')[0]
      },
      pagination: {
        el: $(v).find('.cards-slider__pagination')[0],
        // type: 'bullets',
      },
      breakpoints: {
        1200: {
          slidesPerView: 4,
          spaceBetween: 30,
        }
      }
    });
  });
}

function initHeader() {
  $('.header__menu-btn').click(function(e) {
    e.preventDefault();
    $('.header__bottom').addClass('is-active');
  });

  $('.header__bottom-close').click(function(e) {
    e.preventDefault();
    $('.header__bottom').removeClass('is-active');
  });

  $('.header__search-btn').click(function(e) {
    e.preventDefault();
    $('.header__search-container').addClass('is-active');
  });

  $('.header__search-close').click(function(e) {
    e.preventDefault();
    $('.header__search-container').removeClass('is-active');
  });
}

function initModals() {
  $('.js-open-modal').click(function(e) {
    e.preventDefault();
    $($(this).attr('data-modal')).addClass('is-active');
    $('html').addClass('modal-active');
  });

  $('.js-close-modal').click(function(e) {
    e.preventDefault();
    $(this).closest('.modal').removeClass('is-active');
    $('html').removeClass('modal-active');
  });
}

function initSubscribeForm() {
  $('.js-subscribe-submit').click(function(e) {
    e.preventDefault();
    $(this).closest('.modal').removeClass('is-active');
    $('#thanks').addClass('is-active');
  });
}